<template>
	<v-container
		v-if="getPermission('dashboard::view')"
		fluid
		class="dashboard-main pt-2 pb-2 bg-white"
	>
		<v-row v-if="is_alcon">
			<v-col md="12" class="dashboard-outer-border">
				<v-layout class="align-center">
					<v-flex md8>
						<div class="listing-select">
							<div class="v-select__selections">
								<div class="v-select__selection py-3">Scheduled Services</div>
							</div>
						</div>
					</v-flex>
					<v-flex md4>
						<table width="100%">
							<tr>
								<td class="px-2" width="50%">
									<DatePicker
										v-on:click:clear="
											filter_two.date = [];
											getScheduledServices();
										"
										clearable
										v-model="filter_two.date"
										style="width: 100%"
										hide-top-margin
										hide-details
										:loading="scheduledLoading"
										:disabled="scheduledLoading"
									></DatePicker>
								</td>
								<td class="px-2" width="30%">
									<SelectInput
										v-on:click:clear="
											filter_two.month = null;
											getScheduledServices();
										"
										clearable
										placeholder="Month"
										:items="months"
										v-model="filter_two.month"
										item-text="text"
										item-value="value"
										hide-details
										hide-top-margin
										:loading="scheduledLoading"
										:disabled="scheduledLoading"
									></SelectInput>
								</td>
								<td class="pl-2" width="10%">
									<v-btn
										:loading="scheduledLoading"
										:disabled="scheduledLoading"
										color="blue darken-4 white--text"
										v-on:click="getScheduledServices()"
										class="w-100"
										depressed
										tile
										>Search</v-btn
									>
								</td>
								<td width="10%" class="pl-2">
									<v-menu
										bottom
										left
										origin="center center"
										transition="slide-y-transition"
										rounded="0"
										offset-y
										max-height="400px"
										:close-on-content-click="false"
										content-class="white-background"
									>
										<template v-slot:activator="{ on, attrs }">
											<v-btn
												depressed
												color="blue darken-4"
												class="text-white"
												tile
												:disabled="scheduledLoading"
												v-bind="attrs"
												v-on="on"
											>
												<v-icon>mdi-table-edit</v-icon>
											</v-btn>
										</template>
										<Draggable
											tag="ul"
											v-model="draggableScheduledThead"
											class="draggable-group"
											handle=".draggable-drag-icon"
											v-on:change="updateTable('dashboardschduledservice', scheduled_services.thead)"
										>
											<template v-for="cols in draggableScheduledThead">
												<li
													class="draggable-group-item"
													:key="cols.key"
													v-if="!cols.checkbox && cols.name != 'Image'"
												>
													<v-checkbox
														dense
														v-model="draggableSchduledTheadShow"
														v-bind:value="cols.key"
														:label="cols.name"
														:disabled="cols.fixed || scheduledLoading"
														color="blue"
														hide-details
														class="mt-0 mb-0"
														v-on:change="
															updateTableVisiblity(
																'dashboardschduledservice',
																scheduled_services.thead,
																draggableSchduledTheadShow
															)
														"
													></v-checkbox>
													<v-icon
														v-if="!scheduledLoading && !cols.fixed"
														class="draggable-action draggable-drag-icon"
														right
														color="blue"
														>mdi-drag</v-icon
													>
													<v-icon v-else right class="draggable-action" color="grey">mdi-drag</v-icon>
												</li>
											</template>
										</Draggable>
									</v-menu>
								</td>
							</tr>
						</table>
					</v-flex>
				</v-layout>
				<v-simple-table class="bt-table listing-table dashboard-table-border">
					<template v-slot:default>
						<thead>
							<tr>
								<th v-for="(row, index) in schduledThead" :key="`ser-thead-${index}`" :width="row.width">
									{{ row.name }}
								</th>
							</tr>
						</thead>
						<tbody v-if="scheduled_services.rows.length">
							<tr
								v-for="(row, index) in scheduled_services.rows"
								class="cursor-pointer"
								:key="index"
								v-on:click="go_service_detail(row)"
							>
								<td
									v-for="(theadrow, index) in schduledThead"
									:key="`ser-tbody-${index}`"
									:width="theadrow.width"
								>
									<template
										v-if="
											theadrow.key == 'name' ||
											theadrow.key == 'location' ||
											theadrow.key == 'calibration_location'
										"
									>
										<div class="break-text" style="width: 200px">
											<ShowValue :object="row" :object-key="theadrow.key" :label="theadrow.name"></ShowValue>
										</div>
									</template>
									<template v-else-if="theadrow.key == 'service_status'">
										<Chip
											:color="row.service_color"
											:text="row.service_status"
											:tooltip-text="row.service_tooltip"
										></Chip>
									</template>
									<template v-else-if="theadrow.key == 'expected_date'">
										<div class="d-flex flex-column">
											<div>
												<span class="bold-600 mr-1">Start:</span
												><ShowValue
													:object="row"
													object-key="expected_start_date_formatted"
													label="Start Date (Expected)"
												></ShowValue>
											</div>
											<div>
												<span class="bold-600 mr-1">End:</span
												><ShowValue
													:object="row"
													object-key="expected_end_date_formatted"
													label="End Date (Expected)"
												></ShowValue>
											</div>
										</div>
									</template>
									<template v-else-if="theadrow.key == 'actual_date'">
										<div class="d-flex flex-column">
											<div>
												<span class="bold-600 mr-1">Start:</span
												><ShowValue
													:object="row"
													object-key="actual_start_date_formatted"
													label="Start Date (Actual)"
												></ShowValue>
											</div>
											<div>
												<span class="bold-600 mr-1">End:</span
												><ShowValue
													:object="row"
													object-key="actual_end_date_formatted"
													label="End Date (Actual)"
												></ShowValue>
											</div>
										</div>
									</template>
									<template v-else-if="theadrow.key == 'calibration_point_and_tolerance'">
										<div class="line-clamp-3 break-text" style="width: 350px">
											<ShowValue
												:object="row"
												object-key="calibration_point_and_tolerance"
												label="calibration point and tolerance"
											></ShowValue>
										</div>
									</template>
									<template v-else>
										<ShowValue :object="row" :object-key="theadrow.key" :label="theadrow.name"></ShowValue>
									</template>
								</td>
							</tr>
						</tbody>
						<tfoot v-else>
							<tr v-if="scheduledLoading">
								<td align="center" colspan="15">
									<br />
									<br />
									<br />
									<br />
									<v-layout>
										<v-flex md3></v-flex>
										<v-flex md6>
											<v-progress-linear
												color="blue darken-4"
												indeterminate
												rounded
												height="6"
											></v-progress-linear>
										</v-flex>
										<v-flex md3></v-flex>
									</v-layout>
									<br />
									<br />
									<br />
									<br />
								</td>
							</tr>
							<tr v-else>
								<td align="center" colspan="15">
									<p class="m-0 row-not-found text-center">
										<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
										Uhh... There are no scheduled services at the moment.
									</p>
								</td>
							</tr>
						</tfoot>
					</template>
				</v-simple-table>
				<div class="mt-4">
					<v-layout>
						<v-flex>
							<p>{{ scheduled_services.showing_string }}</p>
						</v-flex>
						<v-flex>
							<v-pagination
								:disabled="scheduledLoading"
								color="blue darken-4"
								v-model="scheduled_services.current_page"
								:length="scheduled_services.total_page"
								:total-visible="7"
								v-on:input="getPagination('service-scheduled', $event)"
							></v-pagination>
						</v-flex>
					</v-layout>
				</div>
			</v-col>
			<v-col md="12" class="dashboard-outer-border">
				<v-layout class="mt-4 align-center">
					<v-flex md8>
						<div class="listing-select">
							<div class="v-select__selections">
								<div class="v-select__selection py-3">Assets</div>
							</div>
						</div>
					</v-flex>
					<v-flex md4>
						<table width="100%">
							<tr>
								<td class="px-2" width="50%">
									<DatePicker
										v-on:click:clear="
											filter_one.date = [];
											getAssets();
										"
										clearable
										v-model="filter_one.date"
										style="width: 100%"
										hide-top-margin
										hide-details
										:loading="assetLoading"
										:disabled="assetLoading"
									></DatePicker>
								</td>
								<td class="px-2" width="30%">
									<SelectInput
										v-on:click:clear="
											filter_one.month = null;
											getAssets();
										"
										clearable
										placeholder="Month"
										:items="months"
										v-model="filter_one.month"
										item-text="text"
										item-value="value"
										hide-details
										hide-top-margin
										:loading="assetLoading"
										:disabled="assetLoading"
									></SelectInput>
								</td>
								<td class="pl-2" width="10%">
									<v-btn
										:loading="assetLoading"
										:disabled="assetLoading"
										color="blue darken-4 white--text"
										class="w-100"
										v-on:click="getAssets()"
										depressed
										tile
										>Search</v-btn
									>
								</td>
								<td width="10%" class="pl-2">
									<v-menu
										bottom
										left
										origin="center center"
										transition="slide-y-transition"
										rounded="0"
										offset-y
										max-height="400px"
										:close-on-content-click="false"
										content-class="white-background"
									>
										<template v-slot:activator="{ on, attrs }">
											<v-btn
												depressed
												color="blue darken-4"
												class="text-white"
												tile
												:disabled="assetLoading"
												v-bind="attrs"
												v-on="on"
											>
												<v-icon>mdi-table-edit</v-icon>
											</v-btn>
										</template>
										<Draggable
											tag="ul"
											v-model="draggableAssetThead"
											class="draggable-group"
											handle=".draggable-drag-icon"
											v-on:change="updateTable('dashboardasset', assets.thead)"
										>
											<template v-for="cols in draggableAssetThead">
												<li
													class="draggable-group-item"
													:key="cols.key"
													v-if="!cols.checkbox && cols.name != 'Image'"
												>
													<v-checkbox
														dense
														v-model="draggableAssetTheadShow"
														v-bind:value="cols.key"
														:label="cols.name"
														:disabled="cols.fixed || assetLoading"
														color="blue"
														hide-details
														class="mt-0 mb-0"
														v-on:change="
															updateTableVisiblity('dashboardasset', assets.thead, draggableAssetTheadShow)
														"
													></v-checkbox>
													<v-icon
														v-if="!assetLoading && !cols.fixed"
														class="draggable-action draggable-drag-icon"
														right
														color="blue"
														>mdi-drag</v-icon
													>
													<v-icon v-else right class="draggable-action" color="grey">mdi-drag</v-icon>
												</li>
											</template>
										</Draggable>
									</v-menu>
								</td>
							</tr>
						</table>
					</v-flex>
				</v-layout>
				<v-simple-table class="bt-table listing-table dashboard-table-border">
					<template v-slot:default>
						<thead>
							<tr>
								<th v-for="(row, index) in assetsThead" :key="`thead-${index}`" :width="row.width">
									{{ row.name }}
								</th>
							</tr>
						</thead>
						<tbody v-if="assets.rows.length">
							<tr
								v-for="(row, index) in assets.rows"
								:key="index"
								class="cursor-pointer"
								v-on:click="go_asset_detail(row)"
							>
								<td
									v-for="(theadrow, index) in assetsThead"
									:key="`tbody-${index}`"
									:width="theadrow.width"
								>
									<template
										v-if="
											theadrow.key == 'name' ||
											theadrow.key == 'location' ||
											theadrow.key == 'calibration_location'
										"
									>
										<div class="break-text" style="width: 200px">
											<ShowValue :object="row" :object-key="theadrow.key" :label="theadrow.name"></ShowValue>
										</div>
									</template>
									<template v-else-if="theadrow.key == 'service_status'">
										<Chip
											:color="row.service_color"
											:text="row.service_status"
											:tooltip-text="row.service_tooltip"
										></Chip>
									</template>
									<template v-else-if="theadrow.key == 'calibration_point_and_tolerance'">
										<div class="line-clamp-3 break-text" style="width: 350px">
											<ShowValue
												:object="row"
												object-key="calibration_point_and_tolerance"
												label="calibration point and tolerance"
											></ShowValue>
										</div>
									</template>
									<template v-else>
										<ShowValue :object="row" :object-key="theadrow.key" :label="theadrow.name"></ShowValue>
									</template>
								</td>
							</tr>
						</tbody>
						<tfoot v-else>
							<tr v-if="assetLoading">
								<td align="center" colspan="13">
									<br />
									<br />
									<br />
									<br />
									<v-layout>
										<v-flex md3></v-flex>
										<v-flex md6>
											<v-progress-linear
												color="blue darken-4"
												indeterminate
												rounded
												height="6"
											></v-progress-linear>
										</v-flex>
										<v-flex md3></v-flex>
									</v-layout>
									<br />
									<br />
									<br />
									<br />
								</td>
							</tr>
							<tr v-else>
								<td align="center" colspan="13">
									<p class="m-0 row-not-found text-center">
										<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
										Uhh... There are no assets at the moment.
									</p>
								</td>
							</tr>
						</tfoot>
					</template>
				</v-simple-table>
				<div class="mt-4">
					<v-layout>
						<v-flex>
							<p>{{ assets.showing_string }}</p>
						</v-flex>
						<v-flex>
							<v-pagination
								color="blue darken-4"
								v-model="assets.current_page"
								:length="assets.total_page"
								:total-visible="7"
								:disabled="assetLoading"
								v-on:input="getPagination('asset', $event)"
							></v-pagination>
						</v-flex>
					</v-layout>
				</div>
			</v-col>
		</v-row>
		<v-row v-else>
			<v-col md="12" class="dashboard-outer-border mb-4">
				<v-layout class="align-center">
					<v-flex md6>
						<div class="listing-select">
							<div class="v-select__selections">
								<div class="v-select__selection py-3">Assets</div>
							</div>
						</div>
					</v-flex>
					<v-flex md6>
						<table width="100%">
							<tr>
								<td class="px-2" width="30%">
									<TextInput
										hide-top-margin
										hide-details
										id="asset-search"
										placeholder="Search"
										clearable
										v-model="filter_one.search"
									></TextInput>
								</td>

								<td class="px-2" width="30%">
									<DatePicker
										v-on:click:clear="
											filter_one.date = [];
											getAssets();
										"
										clearable
										v-model="filter_one.date"
										style="width: 100%"
										hide-top-margin
										hide-details
										:loading="assetLoading"
										:disabled="assetLoading"
									></DatePicker>
								</td>
								<td class="px-2" width="30%">
									<SelectInput
										v-on:click:clear="
											filter_one.month = null;
											getAssets();
										"
										clearable
										placeholder="Month"
										:items="months"
										v-model="filter_one.month"
										item-text="text"
										item-value="value"
										hide-details
										hide-top-margin
										:loading="assetLoading"
										:disabled="assetLoading"
									></SelectInput>
								</td>
								<td class="pl-2" width="10%">
									<v-btn
										:loading="assetLoading"
										:disabled="assetLoading"
										color="blue darken-4 white--text"
										class="w-100"
										v-on:click="getAssets()"
										depressed
										tile
										>Search</v-btn
									>
								</td>
								<td width="10%" class="pl-2">
									<v-menu
										bottom
										left
										origin="center center"
										transition="slide-y-transition"
										rounded="0"
										offset-y
										max-height="400px"
										:close-on-content-click="false"
										content-class="white-background"
									>
										<template v-slot:activator="{ on, attrs }">
											<v-btn
												depressed
												color="blue darken-4"
												class="text-white"
												tile
												:disabled="assetLoading"
												v-bind="attrs"
												v-on="on"
											>
												<v-icon>mdi-table-edit</v-icon>
											</v-btn>
										</template>
										<Draggable
											tag="ul"
											v-model="draggableAssetThead"
											class="draggable-group"
											handle=".draggable-drag-icon"
											v-on:change="updateTable('dashboardasset', assets.thead)"
										>
											<template v-for="cols in draggableAssetThead">
												<li
													class="draggable-group-item"
													:key="cols.key"
													v-if="!cols.checkbox && cols.name != 'Image'"
												>
													<v-checkbox
														dense
														v-model="draggableAssetTheadShow"
														v-bind:value="cols.key"
														:label="cols.name"
														:disabled="cols.fixed || assetLoading"
														color="blue"
														hide-details
														class="mt-0 mb-0"
														v-on:change="
															updateTableVisiblity('dashboardasset', assets.thead, draggableAssetTheadShow)
														"
													></v-checkbox>
													<v-icon
														v-if="!assetLoading && !cols.fixed"
														class="draggable-action draggable-drag-icon"
														right
														color="blue"
														>mdi-drag</v-icon
													>
													<v-icon v-else right class="draggable-action" color="grey">mdi-drag</v-icon>
												</li>
											</template>
										</Draggable>
									</v-menu>
								</td>
							</tr>
						</table>
					</v-flex>
				</v-layout>
				<v-simple-table class="bt-table listing-table dashboard-table-border">
					<template v-slot:default>
						<thead>
							<tr>
								<th v-for="(row, index) in assetsThead" :key="`thead-${index}`" :width="row.width">
									{{ row.name }}
								</th>
							</tr>
						</thead>
						<tbody v-if="assets.rows.length">
							<tr
								v-for="(row, index) in assets.rows"
								:key="index"
								class="cursor-pointer"
								v-on:click="go_asset_detail(row)"
							>
								<td
									v-for="(theadrow, index) in assetsThead"
									:key="`tbody-${index}`"
									:width="theadrow.width"
								>
									<template
										v-if="
											theadrow.key == 'name' ||
											theadrow.key == 'location' ||
											theadrow.key == 'calibration_location'
										"
									>
										<div class="break-text" style="width: 200px">
											<ShowValue :object="row" :object-key="theadrow.key" :label="theadrow.name"></ShowValue>
										</div>
									</template>
									<template v-else-if="theadrow.key == 'service_status'">
										<Chip
											:color="row.service_color"
											:text="row.service_status"
											:tooltip-text="row.service_tooltip"
										></Chip>
									</template>
									<template v-else-if="theadrow.key == 'calibration_point_and_tolerance'">
										<div class="line-clamp-3 break-text" style="width: 350px">
											<ShowValue
												:object="row"
												object-key="calibration_point_and_tolerance"
												label="calibration point and tolerance"
											></ShowValue>
										</div>
									</template>
									<template v-else>
										<ShowValue :object="row" :object-key="theadrow.key" :label="theadrow.name"></ShowValue>
									</template>
								</td>
							</tr>
						</tbody>
						<tfoot v-else>
							<tr v-if="assetLoading">
								<td align="center" colspan="13">
									<br />
									<br />
									<br />
									<br />
									<v-layout>
										<v-flex md3></v-flex>
										<v-flex md6>
											<v-progress-linear
												color="blue darken-4"
												indeterminate
												rounded
												height="6"
											></v-progress-linear>
										</v-flex>
										<v-flex md3></v-flex>
									</v-layout>
									<br />
									<br />
									<br />
									<br />
								</td>
							</tr>
							<tr v-else>
								<td align="center" colspan="13">
									<p class="m-0 row-not-found text-center">
										<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
										Uhh... There are no assets at the moment.
									</p>
								</td>
							</tr>
						</tfoot>
					</template>
				</v-simple-table>
				<div class="mt-4">
					<v-layout>
						<v-flex>
							<p>{{ assets.showing_string }}</p>
						</v-flex>
						<v-flex>
							<v-pagination
								color="blue darken-4"
								v-model="assets.current_page"
								:length="assets.total_page"
								:total-visible="7"
								:disabled="assetLoading"
								v-on:input="getPagination('asset', $event)"
							></v-pagination>
						</v-flex>
					</v-layout>
				</div>
			</v-col>
			<v-col md="12" class="dashboard-outer-border mb-4">
				<v-layout class="align-center">
					<v-flex md8>
						<div class="listing-select">
							<div class="v-select__selections">
								<div class="v-select__selection py-3">Scheduled Services</div>
							</div>
						</div>
					</v-flex>
					<v-flex md4>
						<table width="100%">
							<tr>
								<td class="px-2" width="50%">
									<DatePicker
										v-on:click:clear="
											filter_two.date = [];
											getScheduledServices();
										"
										clearable
										v-model="filter_two.date"
										style="width: 100%"
										hide-top-margin
										hide-details
										:loading="scheduledLoading"
										:disabled="scheduledLoading"
									></DatePicker>
								</td>
								<td class="px-2" width="30%">
									<SelectInput
										v-on:click:clear="
											filter_two.month = null;
											getScheduledServices();
										"
										clearable
										placeholder="Month"
										:items="months"
										v-model="filter_two.month"
										item-text="text"
										item-value="value"
										hide-details
										hide-top-margin
										:loading="scheduledLoading"
										:disabled="scheduledLoading"
									></SelectInput>
								</td>
								<td class="pl-2" width="10%">
									<v-btn
										:loading="scheduledLoading"
										:disabled="scheduledLoading"
										color="blue darken-4 white--text"
										v-on:click="getScheduledServices()"
										class="w-100"
										depressed
										tile
										>Search</v-btn
									>
								</td>
								<td width="10%" class="pl-2">
									<v-menu
										bottom
										left
										origin="center center"
										transition="slide-y-transition"
										rounded="0"
										offset-y
										max-height="400px"
										:close-on-content-click="false"
										content-class="white-background"
									>
										<template v-slot:activator="{ on, attrs }">
											<v-btn
												depressed
												color="blue darken-4"
												class="text-white"
												tile
												:disabled="scheduledLoading"
												v-bind="attrs"
												v-on="on"
											>
												<v-icon>mdi-table-edit</v-icon>
											</v-btn>
										</template>
										<Draggable
											tag="ul"
											v-model="draggableScheduledThead"
											class="draggable-group"
											handle=".draggable-drag-icon"
											v-on:change="updateTable('dashboardschduledservice', scheduled_services.thead)"
										>
											<template v-for="cols in draggableScheduledThead">
												<li
													class="draggable-group-item"
													:key="cols.key"
													v-if="!cols.checkbox && cols.name != 'Image'"
												>
													<v-checkbox
														dense
														v-model="draggableSchduledTheadShow"
														v-bind:value="cols.key"
														:label="cols.name"
														:disabled="cols.fixed || scheduledLoading"
														color="blue"
														hide-details
														class="mt-0 mb-0"
														v-on:change="
															updateTableVisiblity(
																'dashboardschduledservice',
																scheduled_services.thead,
																draggableSchduledTheadShow
															)
														"
													></v-checkbox>
													<v-icon
														v-if="!scheduledLoading && !cols.fixed"
														class="draggable-action draggable-drag-icon"
														right
														color="blue"
														>mdi-drag</v-icon
													>
													<v-icon v-else right class="draggable-action" color="grey">mdi-drag</v-icon>
												</li>
											</template>
										</Draggable>
									</v-menu>
								</td>
							</tr>
						</table>
					</v-flex>
				</v-layout>
				<v-simple-table class="bt-table listing-table dashboard-table-border">
					<template v-slot:default>
						<thead>
							<tr>
								<th v-for="(row, index) in schduledThead" :key="`ser-thead-${index}`" :width="row.width">
									{{ row.name }}
								</th>
							</tr>
						</thead>
						<tbody v-if="scheduled_services.rows.length">
							<tr
								v-for="(row, index) in scheduled_services.rows"
								class="cursor-pointer"
								:key="index"
								v-on:click="go_service_detail(row)"
							>
								<td
									v-for="(theadrow, index) in schduledThead"
									:key="`ser-tbody-${index}`"
									:width="theadrow.width"
								>
									<template
										v-if="
											theadrow.key == 'name' ||
											theadrow.key == 'location' ||
											theadrow.key == 'calibration_location'
										"
									>
										<div class="break-text" style="width: 200px">
											<ShowValue :object="row" :object-key="theadrow.key" :label="theadrow.name"></ShowValue>
										</div>
									</template>
									<template v-else-if="theadrow.key == 'service_status'">
										<Chip
											:color="row.service_color"
											:text="row.service_status"
											:tooltip-text="row.service_tooltip"
										></Chip>
									</template>
									<template v-else-if="theadrow.key == 'expected_date'">
										<div class="d-flex flex-column">
											<div>
												<span class="bold-600 mr-1">Start:</span
												><ShowValue
													:object="row"
													object-key="expected_start_date_formatted"
													label="Start Date (Expected)"
												></ShowValue>
											</div>
											<div>
												<span class="bold-600 mr-1">End:</span
												><ShowValue
													:object="row"
													object-key="expected_end_date_formatted"
													label="End Date (Expected)"
												></ShowValue>
											</div>
										</div>
									</template>
									<template v-else-if="theadrow.key == 'actual_date'">
										<div class="d-flex flex-column">
											<div>
												<span class="bold-600 mr-1">Start:</span
												><ShowValue
													:object="row"
													object-key="actual_start_date_formatted"
													label="Start Date (Actual)"
												></ShowValue>
											</div>
											<div>
												<span class="bold-600 mr-1">End:</span
												><ShowValue
													:object="row"
													object-key="actual_end_date_formatted"
													label="End Date (Actual)"
												></ShowValue>
											</div>
										</div>
									</template>
									<template v-else-if="theadrow.key == 'calibration_point_and_tolerance'">
										<div class="line-clamp-3 break-text" style="width: 350px">
											<ShowValue
												:object="row"
												object-key="calibration_point_and_tolerance"
												label="calibration point and tolerance"
											></ShowValue>
										</div>
									</template>
									<template v-else>
										<ShowValue :object="row" :object-key="theadrow.key" :label="theadrow.name"></ShowValue>
									</template>
								</td>
							</tr>
						</tbody>
						<tfoot v-else>
							<tr v-if="scheduledLoading">
								<td align="center" colspan="15">
									<br />
									<br />
									<br />
									<br />
									<v-layout>
										<v-flex md3></v-flex>
										<v-flex md6>
											<v-progress-linear
												color="blue darken-4"
												indeterminate
												rounded
												height="6"
											></v-progress-linear>
										</v-flex>
										<v-flex md3></v-flex>
									</v-layout>
									<br />
									<br />
									<br />
									<br />
								</td>
							</tr>
							<tr v-else>
								<td align="center" colspan="15">
									<p class="m-0 row-not-found text-center">
										<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
										Uhh... There are no scheduled services at the moment.
									</p>
								</td>
							</tr>
						</tfoot>
					</template>
				</v-simple-table>
				<div class="mt-4">
					<v-layout>
						<v-flex>
							<p>{{ scheduled_services.showing_string }}</p>
						</v-flex>
						<v-flex>
							<v-pagination
								:disabled="scheduledLoading"
								color="blue darken-4"
								v-model="scheduled_services.current_page"
								:length="scheduled_services.total_page"
								:total-visible="7"
								v-on:input="getPagination('service-scheduled', $event)"
							></v-pagination>
						</v-flex>
					</v-layout>
				</div>
			</v-col>
		</v-row>
		<v-row>
			<v-col md="12" class="dashboard-outer-border mb-4">
				<v-layout class="align-center">
					<v-flex md8>
						<div class="listing-select">
							<div class="v-select__selections">
								<div class="v-select__selection py-3">Completed Services</div>
							</div>
						</div>
					</v-flex>
					<v-flex md4>
						<table width="100%">
							<tr>
								<td class="px-2" width="50%">
									<DatePicker
										v-on:click:clear="
											filter_three.date = [];
											getCompletedServices();
										"
										clearable
										v-model="filter_three.date"
										style="width: 100%"
										hide-top-margin
										hide-details
										:loading="completedLoading"
										:disabled="completedLoading"
									></DatePicker>
								</td>
								<td class="px-2" width="30%">
									<SelectInput
										v-on:click:clear="
											filter_three.month = [];
											getCompletedServices();
										"
										clearable
										placeholder="Month"
										:items="months"
										v-model="filter_three.month"
										item-text="text"
										item-value="value"
										hide-details
										hide-top-margin
										:loading="completedLoading"
										:disabled="completedLoading"
									></SelectInput>
								</td>
								<td class="pl-2" width="10%">
									<v-btn
										:loading="completedLoading"
										:disabled="completedLoading"
										color="blue darken-4 white--text"
										v-on:click="getCompletedServices()"
										class="w-100"
										depressed
										tile
										>Search</v-btn
									>
								</td>
								<td width="10%" class="pl-2">
									<v-menu
										bottom
										left
										origin="center center"
										transition="slide-y-transition"
										rounded="0"
										offset-y
										max-height="400px"
										:close-on-content-click="false"
										content-class="white-background"
									>
										<template v-slot:activator="{ on, attrs }">
											<v-btn
												depressed
												color="blue darken-4"
												class="text-white"
												tile
												:disabled="completedLoading"
												v-bind="attrs"
												v-on="on"
											>
												<v-icon>mdi-table-edit</v-icon>
											</v-btn>
										</template>
										<Draggable
											tag="ul"
											v-model="draggableCompletedThead"
											class="draggable-group"
											handle=".draggable-drag-icon"
											v-on:change="updateTable('dashboardcompletedservice', completed_services.thead)"
										>
											<template v-for="cols in draggableCompletedThead">
												<li
													class="draggable-group-item"
													:key="cols.key"
													v-if="!cols.checkbox && cols.name != 'Image'"
												>
													<v-checkbox
														dense
														v-model="draggableCompletedTheadShow"
														v-bind:value="cols.key"
														:label="cols.name"
														:disabled="cols.fixed || completedLoading"
														color="blue"
														hide-details
														class="mt-0 mb-0"
														v-on:change="
															updateTableVisiblity(
																'dashboardcompletedservice',
																completed_services.thead,
																draggableCompletedTheadShow
															)
														"
													></v-checkbox>
													<v-icon
														v-if="!completedLoading && !cols.fixed"
														class="draggable-action draggable-drag-icon"
														right
														color="blue"
														>mdi-drag</v-icon
													>
													<v-icon v-else right class="draggable-action" color="grey">mdi-drag</v-icon>
												</li>
											</template>
										</Draggable>
									</v-menu>
								</td>
							</tr>
						</table>
					</v-flex>
				</v-layout>
				<v-simple-table class="bt-table listing-table dashboard-table-border">
					<template v-slot:default>
						<thead>
							<tr>
								<th v-for="(row, index) in completedThead" :key="`com-thead-${index}`" :width="row.width">
									{{ row.name }}
								</th>
							</tr>
						</thead>
						<tbody v-if="completed_services.rows.length">
							<tr
								v-for="(row, index) in completed_services.rows"
								class="cursor-pointer"
								:key="index"
								v-on:click="go_service_detail(row)"
							>
								<td
									v-for="(theadrow, index) in completedThead"
									:key="`com-tbody-${index}`"
									:width="theadrow.width"
								>
									<template v-if="theadrow.key == 'service_status'">
										<Chip
											:color="row.service_color"
											:text="row.service_status"
											:tooltip-text="row.service_tooltip"
										></Chip>
									</template>
									<template v-else-if="theadrow.key == 'invoice_status'">
										<Chip
											:color="row.invoice_color"
											:text="row.invoice_status"
											:tooltip-text="row.invoice_tooltip"
										></Chip>
									</template>
									<template v-else>
										<ShowValue :object="row" :object-key="theadrow.key" :label="theadrow.name"></ShowValue>
									</template>
								</td>
							</tr>
						</tbody>
						<tfoot v-else>
							<tr v-if="completedLoading">
								<td align="center" colspan="8">
									<br />
									<br />
									<br />
									<br />
									<v-layout>
										<v-flex md3></v-flex>
										<v-flex md6>
											<v-progress-linear
												color="blue darken-4"
												indeterminate
												rounded
												height="6"
											></v-progress-linear>
										</v-flex>
										<v-flex md3></v-flex>
									</v-layout>
									<br />
									<br />
									<br />
									<br />
								</td>
							</tr>
							<tr v-else>
								<td align="center" colspan="8">
									<p class="m-0 row-not-found text-center">
										<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
										Uhh... There are no completed services at the moment.
									</p>
								</td>
							</tr>
						</tfoot>
					</template>
				</v-simple-table>
				<div class="mt-4">
					<v-layout>
						<v-flex>
							<p>{{ completed_services.showing_string }}</p>
						</v-flex>
						<v-flex>
							<v-pagination
								:disabled="completedLoading"
								color="blue darken-4"
								v-model="completed_services.current_page"
								:length="completed_services.total_page"
								:total-visible="7"
								v-on:input="getPagination('service-completed', $event)"
							></v-pagination>
						</v-flex>
					</v-layout>
				</div>
			</v-col>
			<v-col md="12" class="dashboard-outer-border">
				<div class="listing-select">
					<div class="v-select__selections">
						<div class="v-select__selection py-3">Certificates</div>
					</div>
				</div>
				<v-simple-table class="bt-table listing-table dashboard-table-border">
					<template v-slot:default>
						<thead>
							<tr>
								<th>Asset ID</th>
								<th>Asset Name</th>
								<th>View Certificates</th>
								<th>Download Certificates</th>
							</tr>
						</thead>
						<tbody v-if="certificates.rows.length">
							<tr v-for="(row, index) in certificates.rows" :key="index">
								<td><ShowValue :object="row" object-key="id_number" label="asset id"></ShowValue></td>
								<td><ShowValue :object="row" object-key="name" label="asset name"></ShowValue></td>
								<td>
									<v-icon color="blue darken-4" v-on:click="downloadFile(row, 'view')">mdi-eye</v-icon>
								</td>
								<td>
									<v-icon color="blue darken-4" v-on:click="downloadFile(row, 'download')"
										>mdi-download</v-icon
									>
								</td>
							</tr>
						</tbody>
						<tfoot v-else>
							<tr v-if="certificateLoading">
								<td align="center" colspan="4">
									<br />
									<br />
									<br />
									<br />
									<v-layout>
										<v-flex md3></v-flex>
										<v-flex md6>
											<v-progress-linear
												color="blue darken-4"
												indeterminate
												rounded
												height="6"
											></v-progress-linear>
										</v-flex>
										<v-flex md3></v-flex>
									</v-layout>
									<br />
									<br />
									<br />
									<br />
								</td>
							</tr>
							<tr v-else>
								<td align="center" colspan="4">
									<p class="m-0 row-not-found text-center">
										<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
										Uhh... There are no certificates at the moment.
									</p>
								</td>
							</tr>
						</tfoot>
					</template>
				</v-simple-table>
				<div class="mt-4">
					<v-layout>
						<v-flex>
							<p>{{ certificates.showing_string }}</p>
						</v-flex>
						<v-flex>
							<v-pagination
								:disabled="certificateLoading"
								color="blue darken-4"
								v-model="certificates.current_page"
								:length="certificates.total_page"
								:total-visible="7"
								v-on:input="getPagination('certificates', $event)"
							></v-pagination>
						</v-flex>
					</v-layout>
				</div>
			</v-col>
		</v-row>
		<AssetServiceDetail
			:uuid="detail_uuid"
			:dialog="detail_dialog"
			v-on:close="detail_dialog = false"
		></AssetServiceDetail>
	</v-container>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import DatePicker from "@/view/components/DateRangePicker";
import SelectInput from "@/view/components/SelectInput";
import ShowValue from "@/view/components/ShowValue";
import Chip from "@/view/components/Chip";
import ApiService from "@/core/services/api.service";
import AssetServiceDetail from "@/view/components/Asset-Service-Detail";
import ObjectPath from "object-path";
import JwtService from "@/core/services/jwt.service";
import Draggable from "vuedraggable";
import { cloneDeep, orderBy, filter, includes } from "lodash";
import { UPDATE_TABLE } from "@/core/services/store/listing.module";
import TextInput from "@/view/components/TextInput";

export default {
	name: "dashboard",
	data() {
		return {
			detail_uuid: null,
			detail_dialog: false,
			is_alcon: false,
			months: [
				{ value: "january", text: "January" },
				{ value: "february", text: "February" },
				{ value: "march", text: "March" },
				{ value: "april", text: "April" },
				{ value: "may", text: "May" },
				{ value: "june", text: "June" },
				{ value: "july", text: "July" },
				{ value: "august", text: "August" },
				{ value: "september", text: "September" },
				{ value: "october", text: "October" },
				{ value: "november", text: "November" },
				{ value: "december", text: "December" },
			],
			filter_one: {
				month: null,
				date: [],
				search: null,
			},
			filter_two: {
				month: null,
				date: [],
			},
			filter_three: {
				month: null,
				date: [],
			},
			assetLoading: false,
			assets: {
				rows: [],
				thead: [],
				current_page: 1,
				showing_string: null,
				tempTheadShow: null,
				theadShow: null,
				total_page: 0,
			},
			scheduledLoading: false,
			scheduled_services: {
				rows: [],
				current_page: 1,
				showing_string: null,
				total_page: 0,
				thead: [],
				tempTheadShow: null,
				theadShow: null,
			},
			completedLoading: false,
			completed_services: {
				rows: [],
				current_page: 1,
				showing_string: null,
				total_page: 0,
				thead: [],
				tempTheadShow: null,
				theadShow: null,
			},
			certificateLoading: false,
			certificates: {
				rows: [],
				current_page: 1,
				showing_string: null,
				total_page: 0,
			},
		};
	},
	methods: {
		go_asset_detail({ uuid }) {
			this.$router.push({
				name: "asset-detail",
				params: { uuid },
				query: { t: new Date().getTime() },
			});
		},
		go_service_detail({ uuid }) {
			this.detail_uuid = uuid;
			this.detail_dialog = true;
		},
		getPagination(type, page) {
			if (type == "asset") {
				this.assets.current_page = page;
				this.getAssets();
			}

			if (type == "service-scheduled") {
				this.scheduled_services.current_page = page;
				this.getScheduledServices();
			}

			if (type == "service-completed") {
				this.completed_services.current_page = page;
				this.getCompletedServices();
			}

			if (type == "certificates") {
				this.certificates.current_page = page;
				this.getCertificates();
			}
		},
		getAssets() {
			this.assetLoading = true;

			const query = {
				"date-range": this.filter_one.date,
				month: this.filter_one.month,
				search: this.filter_one.search,
				page: this.assets.current_page,
			};

			this.assets.rows = [];
			this.assets.current_page = 1;
			this.assets.showing_string = null;
			this.assets.total_page = 0;

			ApiService.query(`dashboard/assets`, query)
				.then((output) => {
					this.assets.rows = ObjectPath.get(output, "data.tbody", []);
					this.assets.thead = ObjectPath.get(output, "data.thead", []);
					this.assets.current_page = ObjectPath.get(output, "data.current_page", 1);
					this.assets.showing_string = ObjectPath.get(output, "data.showing_string", null);
					this.assets.total_page = ObjectPath.get(output, "data.total_page", 0);
					const result = orderBy(this.assets.thead, ["order"], ["asc"]);
					this.assets.theadShow = result ? filter(result, { visible: true }).map((col) => col.key) : [];
				})
				.catch(({ response }) => {
					console.error({ response });
				})
				.finally(() => {
					this.assetLoading = false;
				});
		},
		getScheduledServices() {
			this.scheduledLoading = true;

			const query = {
				"date-range": this.filter_two.date,
				month: this.filter_two.month,
				page: this.scheduled_services.current_page,
			};

			this.scheduled_services.rows = [];
			this.scheduled_services.current_page = 1;
			this.scheduled_services.showing_string = null;
			this.scheduled_services.total_page = 0;

			ApiService.query(`dashboard/scheduled-services`, query)
				.then((output) => {
					this.scheduled_services.rows = ObjectPath.get(output, "data.tbody", []);
					this.scheduled_services.current_page = ObjectPath.get(output, "data.current_page", 1);
					this.scheduled_services.showing_string = ObjectPath.get(output, "data.showing_string", null);
					this.scheduled_services.total_page = ObjectPath.get(output, "data.total_page", 0);
					this.scheduled_services.thead = ObjectPath.get(output, "data.thead", []);
					const result = orderBy(this.scheduled_services.thead, ["order"], ["asc"]);
					this.scheduled_services.theadShow = result
						? filter(result, { visible: true }).map((col) => col.key)
						: [];
				})
				.catch(({ response }) => {
					console.error({ response });
				})
				.finally(() => {
					this.scheduledLoading = false;
				});
		},
		getCompletedServices() {
			this.completedLoading = true;

			const query = {
				"date-range": this.filter_three.date,
				month: this.filter_three.month,
				page: this.completed_services.current_page,
			};

			this.completed_services.rows = [];
			this.completed_services.current_page = 1;
			this.completed_services.showing_string = null;
			this.completed_services.total_page = 0;

			ApiService.query(`dashboard/completed-services`, query)
				.then((output) => {
					this.completed_services.rows = ObjectPath.get(output, "data.tbody", []);
					this.completed_services.current_page = ObjectPath.get(output, "data.current_page", 1);
					this.completed_services.showing_string = ObjectPath.get(output, "data.showing_string", null);
					this.completed_services.total_page = ObjectPath.get(output, "data.total_page", 0);
					this.completed_services.thead = ObjectPath.get(output, "data.thead", []);
					const result = orderBy(this.completed_services.thead, ["order"], ["asc"]);
					this.completed_services.theadShow = result
						? filter(result, { visible: true }).map((col) => col.key)
						: [];
				})
				.catch(({ response }) => {
					console.error({ response });
				})
				.finally(() => {
					this.completedLoading = false;
				});
		},
		getCertificates() {
			this.certificateLoading = true;

			const query = { page: this.certificates.current_page };

			this.certificates.rows = [];
			this.certificates.current_page = 1;
			this.certificates.showing_string = null;
			this.certificates.total_page = 0;

			ApiService.query(`dashboard/certificates`, query)
				.then((output) => {
					this.certificates.rows = ObjectPath.get(output, "data.tbody", []);
					this.certificates.current_page = ObjectPath.get(output, "data.current_page", 1);
					this.certificates.showing_string = ObjectPath.get(output, "data.showing_string", null);
					this.certificates.total_page = ObjectPath.get(output, "data.total_page", 0);
				})
				.catch(({ response }) => {
					console.error({ response });
				})
				.finally(() => {
					this.certificateLoading = false;
				});
		},
		downloadFile(row) {
			let token = JwtService.getToken();
			window.open(`${this.$apiURL()}download?url=${row.url}&token=${token}`);
		},
		updateTableVisiblity(type, thead, draggableTheadShow) {
			let result = cloneDeep(thead);
			for (let i = 0; i < result.length; i++) {
				result[i].visible = true;
				if (!includes(draggableTheadShow, result[i].key)) {
					result[i].visible = false;
				}
			}
			result = orderBy(result, ["order"], ["asc"]);
			if (type == "dashboardasset") {
				this.assets.thead = result;
			} else if (type == "dashboardschduledservice") {
				this.scheduled_services.thead = result;
			} else if (type == "dashboardcompletedservice") {
				this.completed_services.thead = result;
			}
			this.updateTable(type, result);
		},
		updateTable(type, result) {
			const params = new Object({
				tables: result,
			});
			this.$store.dispatch(UPDATE_TABLE, { type, params }).catch((error) => {
				console.error({ error });
			});
		},
	},
	mounted() {
		const currentUser = JwtService.getCurrentUser();
		if (currentUser.is_alcon == 1) {
			this.is_alcon = true;
		}
		this.$store.dispatch(SET_BREADCRUMB, [{ text: "Dashboard", disabled: true }]);
		this.getAssets();
		this.getScheduledServices();
		this.getCompletedServices();
		this.getCertificates();
	},
	computed: {
		draggableAssetThead: {
			set(val) {
				let result = cloneDeep(val);
				for (let i = 0; i < result.length; i++) {
					if (!result[i].fixed) {
						result[i].order = i;
					}
				}
				result = orderBy(result, ["order"], ["asc"]);
				this.assets.thead = result;
			},
			get() {
				return orderBy(this.assets.thead, ["order"], ["asc"]) || [];
			},
		},
		draggableScheduledThead: {
			set(val) {
				let result = cloneDeep(val);
				for (let i = 0; i < result.length; i++) {
					if (!result[i].fixed) {
						result[i].order = i;
					}
				}
				result = orderBy(result, ["order"], ["asc"]);
				this.scheduled_services.thead = result;
			},
			get() {
				return orderBy(this.scheduled_services.thead, ["order"], ["asc"]) || [];
			},
		},
		draggableCompletedThead: {
			set(val) {
				let result = cloneDeep(val);
				for (let i = 0; i < result.length; i++) {
					if (!result[i].fixed) {
						result[i].order = i;
					}
				}
				result = orderBy(result, ["order"], ["asc"]);
				this.completed_services.thead = result;
			},
			get() {
				return orderBy(this.completed_services.thead, ["order"], ["asc"]) || [];
			},
		},
		draggableAssetTheadShow: {
			set(val) {
				this.assets.tempTheadShow = cloneDeep(val);
			},
			get() {
				return this.assets.tempTheadShow || this.assets.theadShow || [];
			},
		},
		draggableSchduledTheadShow: {
			set(val) {
				this.scheduled_services.tempTheadShow = cloneDeep(val);
			},
			get() {
				return this.scheduled_services.tempTheadShow || this.scheduled_services.theadShow || [];
			},
		},
		draggableCompletedTheadShow: {
			set(val) {
				this.completed_services.tempTheadShow = cloneDeep(val);
			},
			get() {
				return this.completed_services.tempTheadShow || this.completed_services.theadShow || [];
			},
		},
		assetsThead() {
			let result = filter(cloneDeep(this.assets.thead), (row) => row.visible);
			return result;
		},
		schduledThead() {
			let result = filter(cloneDeep(this.scheduled_services.thead), (row) => row.visible);
			return result;
		},
		completedThead() {
			let result = filter(cloneDeep(this.completed_services.thead), (row) => row.visible);
			return result;
		},
	},
	components: {
		TextInput,
		Chip,
		ShowValue,
		Draggable,
		DatePicker,
		SelectInput,
		AssetServiceDetail,
	},
};
</script>
